
.contentContainer {
    display: flex;

    flex-direction: column;


    height: 100%; // 这是为了确保容器占据全部可用高度，你可以根据需要进行调整
    
}
.CardsContainer{
    display: flex;
    justify-content: center; // 垂直居中
    align-items: center;
    width: 100%;
}

.Cards{
    width: 100%;
    align-items: center; // 水平居中
    justify-content: center; // 垂直居中
    align-items: center;
}

.CardsContainer1{
    @extend .CardsContainer;
    margin-top: 10px;
}

.card1Content{
    display: flex;
  justify-content: space-between;
    padding: 0 5% 0 5%;
  flex-wrap: wrap;
  
}
:global(.BasicInfo_CardsContainer1__AAwIz .ant-card-body){
    padding-top: 0px;

  }
.Cards1Title{
    text-align: center;
    margin: 0;
    margin-top:10.77px;
    font-size: 20px;
    font-weight: 750;  
}
.card1Subcontainer{
    display: flex;
  flex-direction: column; // 使内容垂直排列

  
  width: 25%;
  min-width: 100px;
  margin-bottom: 3px;

}
.card1Subtitle {
    margin-top: 10.88px;
    font-size: 9pt;
    
    color: #6F131D;
    
    font-weight: 750;  
    }
.card1text {
    font-size: 9pt;
    max-width: 140px;

    
    font-weight: 750;  
    }
.CardsContainer2{
    @extend .CardsContainer;
    margin-top: 20px;
}
.numberDisplay {
    margin-top: 30px;

    display: inline-block;
    background-color: #136f560f;
    border: 2px solid #136f56;
    border-radius: 20px; // Adjust as needed for desired roundedness
    padding: 10px 20px; // Adjust for desired spacing
    color:#136f56; // Text color
    font-size: 24px; // Adjust for desired text size
    font-weight: bold; // Makes the number bold
    width:85%;
    max-width: 160px;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center; // 水平居中
    justify-content: center; // 垂直居中
  }
  
.card2Subcontainer{
    display: flex;
    justify-content: space-between;
}
.Cards2Title{
    @extend .Cards1Title;
    margin-top: 0;
}
.card22Subcontainer{
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center; // 水平居中
}

.card21Subcontainer{
    @extend .card22Subcontainer;

}
.statusText{
    @extend .numberDisplay;
    border: 2px solid #41060d;
    color: #6F131D;
    background-color: #6f131e2d;
}

.CardsContainer3 {
    margin-top: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
  }

  .Cards1{
    min-width: 125px;
    width: 20.5%;
}
.cardContent {
display: flex;
flex-direction: column;
align-items: center; // 水平居中
justify-content: center; // 垂直居中
height: 100%; // 使用100%的高度使内容填满整个Card
}

.card3Subtitle {
font-size: 11pt;

color: #6F131D;

font-weight: 750;  
}

.cardValue {
font-size: 20pt;  
font-weight: bold; 
}

.buttonGroup{
    position: relative;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  
    
  }

  .btn {
    border: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
.buttonRedGroup {
    margin-top: 16px;
    @extend .buttonGroup;
    
  }

.btnRed {
    @extend .btn;
    color: #6f131e8b;
    border-radius: 4px;
    width: auto;
    padding: 5px 12px;
    margin-right: 4px;
    background-color: transparent;
    &:hover {
        background-color: #6F131D;
        color: #e0e0e0;  
    }


}
.cardTitle {
    font-size: 11pt;

    color: #6F131D;

    font-weight: 750;  
}
.activeRed {
    
    background-color: #6f131e20;
    color: #6F131D;  
}