// TableComponent.module.scss
.customTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    font-size: 14px;
    text-align: left;
    border-radius: 4px; // 为整个表格添加圆角
    overflow: hidden;   // 隐藏超出的部分
    text-align: center;
    font-weight: 500;
    
    th, td {
      padding-top: 8.2px;
      padding-bottom: 7.1px;
      
    }
  
    th {
      background-color: #134F6F;
      color: #ffffff;
      font-weight: 500;
      &:first-child {
        border-top-left-radius: 4px;  // 为左上角的th元素添加圆角
      }
  
      &:last-child {
        border-top-right-radius: 4px;  // 为右上角的th元素添加圆角
      }
    }
  
    tbody {
      tr {
      
        background-color: #f2f2f2;
  
        &:hover {
          background-color: #e6e6e6;
        }
      }
  
      td {
        border-right: 1px solid transparent;
  
        &:last-child {
          border-right: none;
        }
      }
  
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 4px;  // 为左下角的td元素添加圆角
        }
  
        td:last-child {
          border-bottom-right-radius: 4px;  // 为右下角的td元素添加圆角
        }
      }
    }
  }