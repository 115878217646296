// index.module.scss
.bottomStatement {
  width: 100%;
  padding: 20px;
  background-color: #f0f3fa; // 浅灰色背景
  color: #333; // 深灰色文字
  text-align: center;
  box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.2); // 向内的上边缘阴影
  margin-top: auto; // 确保组件自动推到父元素的底部
  align-items: center; // 水平居中
  display: flex;
  flex-direction: column;
}

.statementLine {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .bottomStatement {
    text-align: left;
    padding: 20px 10%;
  }
}
