.contentContainer {
    display: flex;
    margin-left : 3.8vw;
    margin-right: 3.8vw;
    flex-direction: column;
    padding: 20px;
    justify-content: center; // 垂直居中
    height: 100%; // 这是为了确保容器占据全部可用高度，你可以根据需要进行调整

    .formContainer{
        margin-top: 20px;
        width: 70%;
        display: flex;

        flex-direction: column;

        .littleComponent{
            width: 300px;
        }

        .uploader{
            margin-top: 10px;
            margin-bottom: 10px;

        
        }
    }
    
}


.card{
    display: flex;
    align-items: center; // 水平居中;
    background-color: #ffffff;
    border-radius: 15px;
    flex-direction: column;
    padding: 5px 0 17px 0 ;

    .headerContainer{
        display: flex;
        align-items: center; // 水平居中;
        align-items: center; /* 如果你还想垂直居中 */
        position: relative;
        width: 100%;
        .returnButton{
            margin: 0px 0 0 20px;
            position: absolute;
            width: 50px;       /* 设置按钮的宽度 */
            height: 50px;      /* 设置按钮的高度 */
            border-radius: 50%; /* 设置边界半径为 50% 来创建圆形 */
            border: none;      /* 移除边框 */
            background-color: #007bff; /* 设置背景颜色 */
            color: white;      /* 设置文字颜色 */
            text-align: center; /* 文字居中 */
            line-height: 50px; /* 使文字垂直居中 */
            cursor: pointer;   /* 鼠标悬停时显示指针 */
            outline: none;     /* 移除焦点时的轮廓 */
            transition: background-color 0.3s; /* 背景色变换动画 */
    
            &:hover {
    
                background-color: darken(#007bff, 10%);
            }
            .anticon {
                font-size: 24px;
            }
        }
        .header{
            text-align: center;
            margin: 0 auto;
            margin-top:10.77px;
            font-size: 25px;
            margin-bottom: 10.77px;  
        
            font-weight: 750;  
        }
    }
}