.reactShapeApp {

    width: 100%;
    height: 100%;

    padding: 0;
    font-family: sans-serif;
    
    .appContent {
      width: 100%;
      height: 100%;
      
      margin-right: 8px;
      margin-left: 8px;
      border-radius: 5px;

      
    }
    .node{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 8px;
      color: #fff;
      border: 0;
      width: 100%;
      height: 100%;
      font-size: 20px;
    }

    .mainNode{
      @extend .node;
      background-color: #191919;
    }

    .peerNode{
      @extend .node;
      background-color: #1fbe93;
      
    }
    .subNode {
      @extend .node;
      background-color: #134F6F;

      border: 0;

    }
    
    .lightRedNode{
      @extend .node;
      background-color: #f1e5e5;
    }

    .darkRedNode{
      @extend .node;
      background-color: #6F131D;
    }
    .customReactNode span {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }