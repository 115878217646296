.contentContainer {
    display: flex;
    margin-left : 3.8vw;
    margin-right: 3.8vw;
    flex-direction: column;
    padding: 20px;
    justify-content: center; // 垂直居中
    
    height: 100%; // 这是为了确保容器占据全部可用高度，你可以根据需要进行调整
    
}
.firstRow{
  position: relative;
  display: flex;
  align-items: center; // 水平居中
  justify-content: center; // 垂直居中
}
.timeWidgetContainer {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
}


.buttonGroup{
  position: relative;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.TopTitle{
  text-align: center;
  margin: 0;
  margin-top:10.77px;
  font-size: 20px;
  margin-bottom: 10.77px;  

  font-weight: 750;  
}

.btn {
  border: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}
.buttonGroup1 {
    @extend .buttonGroup;
    width: 300px;
    background-color: #ffffff;
    padding: 4px;
    left: 50%;
    transform: translate(-50%, 0);
}
  
  .btn1 {
    @extend .btn;
    background-color: #e0e0e0;
    
    padding: 5px 0px;
    border-radius: 8px;
    cursor: pointer;
    
    color: #134F6F;
    font-size: 10pt;
    width: 60px;
    &:hover { 
        background-color: #d0d0d0;
    }
  }
    .active {
        background-color: #134F6F;
        
        color: white;
    }
  //second row
  .CardsContainer1 {
    margin-top: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
  }
  .Cards1{
      min-width: 125px;
      width: 16.5%;
  }
  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center; // 水平居中
    justify-content: center; // 垂直居中
    height: 100%; // 使用100%的高度使内容填满整个Card
  }
  
  .cardTitle {
    font-size: 11pt;

    color: #6F131D;

    font-weight: 750;  
}

  .cardValue {
    font-size: 20pt;  
    font-weight: bold; 
  }

.CardsContainer2{
    margin-top: 30px;;
    display: flex;

    justify-content: center; // 垂直居中
    align-items: center;
    width: 100%;
}

.Cards2{
    width: 100%;
    align-items: center; // 水平居中
    justify-content: center; // 垂直居中
    align-items: center;

    :global(.ant-card-body){
  padding-top: 16.5px;
  padding-bottom: 17px;
}
}

.Cards2Title{
    text-align: center;
    margin: 0;
    margin-top:10.77px;
    font-size: 20px;
    margin-bottom: 10.77px;  

    font-weight: 750;  
}

.buttonRedGroup {
    @extend .buttonGroup;
    
  }

.btnRed {
    @extend .btn;
    color: #6f131e8b;
    border-radius: 4px;
    width: auto;
    padding: 5px 12px;
    margin-right: 4px;
    background-color: transparent;
    &:hover {
        background-color: #6F131D;
        color: #e0e0e0;  
    }


}

.activeRed {
    
    background-color: #6f131e20;
    color: #6F131D;  
}

  
.chartContainer{
    margin-top: 15.89px;
    margin-bottom: 11.13px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 100%;
}  
.chartColumn{
    
    width: 70%;
}

.Cards3{
    width: 48%;

    align-items: center; // 水平居中
    justify-content: center; // 垂直居中
    display: flex;
    flex-direction: column;

}

.Cards3Title{
  @extend .Cards2Title;
  margin-top: 0;
}



.buttonGroup3{
  @extend .buttonGroup;
  padding-top: 16px;
}

.btn3{
  @extend .btnRed;
  color: #134f6f94;
  padding: 5px 0px;
  &:hover {
    background-color: #134F6F;
    color: #e0e0e0;  
  }
}

.active3 {
  background-color: #134f6f27;
  color: #134F6F;  
}

.ChoiceContainer3{
  margin-top: 10px;
  text-align: center;
}