:global(.ant-layout-sider-children){
    background-color: #ffffff;
    padding: 20px;
}

.linkContainer{
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #6F131D;
    padding-left: 5px;
    padding-bottom: 3.5vh;

}
.siderContainer {
    background-color: #ffffff;
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.1);
    
    min-height: 93vh;
  }
  .internalContainer{
    position: relative;
    padding-top: 5.6vh;
    
  }
  .menuContainer {
    background-color: #ffffff;
    border: none;
    border-inline-end:none !important;
  
  

    .menuItem {
      font-size: 16px;
      font-weight: 500;
      color: #6F131D;
      background-color: #f0eeeeb4;
      margin-top: 10px !important;
      &:hover {
        background-color: #8c5b5b !important;
        color: #ffffff !important;
      }
      &:active{
          background-color: #8c5b5b !important;
      }
    }
  }
    :global(.ant-menu-item-selected) {
        background-color: #6F131D !important;
        color: #ffffff !important;
    }

    .timeWidgetContainer{
        justify-content: center; // 垂直居中
        background-color: #ffffff;
        display: flex;
        align-self: flex-end;
    }

.layoutContent{
  padding: 18px 2.5% 20px 2.5%;
}

.breadcrumbText{
  font-size: 11pt;

  font-weight: 750;  
}