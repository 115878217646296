.contentContainer{
    display: flex;
    align-items: center; // 水平居中;
    flex-direction: column;
}


.CardsTitle {
    text-align: center;
    
    margin: 0;
    font-size: 20px;
    font-weight: 750;  
}

.subTitle {
    @extend .CardsTitle;
    font-size: 17px;
}

.subTitle21{
    @extend .subTitle;
    margin-bottom: 0;
    color: #136F56;
}

.subTitle22{

    font-size: 13px;
    margin-bottom: 0;
}
.subTitle23{
    margin-bottom: 0;
    @extend .subTitle;
    color: #134F6F;
}
.cardContainer{

    display: flex;
    align-items: center; // 水平居中;
    background-color: #ffffff;
    border-radius: 15px;
    flex-direction: column;

    width: 100%;
}


.cardContainer1{
    @extend .cardContainer;
    padding: 50px 0px 40px 0px ;
    
    .progress{
        width: 90%;
    }

}

.cardContainer2{
    @extend .cardContainer;
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    width: 100%;
}


.subContainer21{
    width: 30%;
    display: flex;
    align-items: center; // 水平居中;
    flex-direction: column;
    height: 100%;
}

.subContainer22{
    @extend .subContainer21;
    width: calc(70% - 180px);
    margin-bottom: 10px;
    .selectContainer{
        width: 80%;
    }

    .midRightContainer{ 
        width: 20%;
        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 如需水平居中也可以添加 */
    }
    
    .midDropContainer{
        display: flex;
        width: 100%;    
        align-items: center; /* 垂直居中 */
        .leftMidItem{
            @extend .dragItem;
            color: #ffffff;
            background-color: #136F56;
            &:hover {
                background-color: #136f56aa;
                color: #ffffff;
                }

        }
      

    .rightMidItem{
        @extend .dragItem;
        color: #ffffff;
        background-color: #134F6F;
        margin-top: 0px !important;
        &:hover {
            background-color: #134f6faa;
            color: #ffffff;
            }
    }
}
}

.subContainer23{
    @extend .subContainer21;
    width: 180px;
    height: auto;
}
.subCard{
    width: 80%;
    @extend .cardContainer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: 20px 0px 0px 0;
    padding: 10px 0px 10px 0px;
    min-height: 200px;
}

.subCard1{
    @extend .subCard;
    height: 100%;
}

.subCard2{
    @extend .subCard;
    height: 100%;
}
.buttonGroup{
    position: relative;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .btn {
    border: none;
    font-size: 9px;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    width: auto;
    padding: 2px 6px;
    margin-right: 4px;
    background-color: transparent;
  }

    .buttonGroup1{
    @extend .buttonGroup;
    padding-top: 16px;
  
  
    .btn {
        @extend .btn;
        color: #136f567b;
        
        &:hover {
            background-color: #136F56;
            color: #ffffff;  
        }

        
        }
        .active {
            background-color: #136f562b;
            color: #136F56;  
        }
    }

    .buttonGroup2{
        @extend .buttonGroup;
        padding-top: 16px;
      
      
        .btn {
            @extend .btn;
            color: #134f6f7d;
            
            &:hover {
                background-color: #134F6F;
                color: #ffffff;  
            }
    
            
            }
            .active {
                background-color: #134f6f31;
                color: #134F6F;  
            }
    }

.roundedButton {
    margin-top: 21px;
    border-radius: 25px;  
    color: #ffffff;
    background-color: #136F56;
    margin-right: 2.7vw;
    height: 41px;
    width: 170px;
    border: 0;

    &:hover {
        background-color: #136f56a5;
    }
}  


.itemContainer{
    display: flex;
    align-items: center; // 水平居中;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
}
.dragItem{
    width: 90%;
    font-size: 10px;
    font-weight: 500;
    
    border-radius: 6px;
    border: 0;
    margin-top: 10px;
    text-align: center ;
    padding: 5px 0px 5px 0px;
}

.leftItem{
    @extend .dragItem;
    color: #136F56;
    background-color: #136f562b;
    &:hover {
        background-color: #136f56aa;
        color: #ffffff;
        }

}




.rightItem{
    @extend .dragItem;
    color: #134F6F;
    background-color: #134f6f31;
    &:hover {
        background-color: #134f6faa;
        color: #ffffff;
        }
}