.redButton {

    color: #6F131D;
    background-color: #f0eeeeb4;

    border-radius: 8px;
    border: none;

    font-size: 13pt;
    
    
    font-weight: 700;  
     
    &:hover {
      background-color: #8c5b5b;
      color: #ffffff;
    }
    &:active{
        background-color: #6F131D;
        color: #ffffff;
    }
    &:disabled{
        background-color: #F1F6F5;
        color: #136f5692;
    }
    
}

.active {
    // 你的激活状态样式，例如：
    background-color: #6F131D;
    color: #ffffff;
}
