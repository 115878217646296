.cardContainer{

    display: flex;
    align-items: center; // 水平居中;
    background-color: #ffffff;
    border-radius: 15px;
    flex-direction: column;
    padding: 5px 0 17px 0 ;
    .graphContainer{
        display: flex;
        align-items: center; // 水平居中;
        flex-direction: column;
        width: 100%;
    }
    .CardsTitle {
        text-align: center;
        margin: 0;
        margin-top:10.77px;
        font-size: 20px;
        font-weight: 750;  
    }

    .chart{
        width: 80%;
    }
}




.sliderContainer{
    margin-top: 60px;
    width: 60%;
}

.buttonGroup{
    position: relative;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .activeRed {
    
        background-color: #6f131e20;
        color: #6F131D;  
    }
    
  }

  .btn {
    border: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
.buttonRedGroup {
    margin-top: 16px;
    @extend .buttonGroup;
    
  }

.btnRed {
    @extend .btn;
    color: #6f131e8b;
    border-radius: 4px;
    width: auto;
    padding: 5px 12px;
    margin-right: 4px;
    background-color: transparent;
    &:hover {
        background-color: #6F131D;
        color: #e0e0e0;  
    }


}