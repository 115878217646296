
.Card{
    display: flex;
    align-items: center; // 水平居中;
    background-color: #ffffff;
    border-radius: 15px;
    flex-direction: column;
    padding: 5px 0 17px 0 ;
}
.header{
    text-align: center;
    margin: 0;
    margin-top:10.77px;
    font-size: 20px;
    margin-bottom: 10.77px;  

    font-weight: 750;  
}

.subHeader{
    @extend .header;

    font-size: 14px;
}
.subHeader2{
    @extend .subHeader;

    margin-bottom: 0px;
}

.buttomHeader{
    @extend .header;
    margin-top: 11px;
    margin-bottom: 27px;

}
.topContainer{
    display: flex;
    justify-content: space-between; /* This will distribute items evenly */

    
}

.buttomContainer{
    @extend .topContainer;
    margin-top: 20px;
}
.topSubContainer{
    width: 32%;

}
.riskCard{
    @extend .Card;

    height: 167px;

    display: flex;
    justify-content: center;
    flex-direction: column;
   .level{
    font-size: 30pt;
    font-weight: 750;  
    }

    .highLevel{
        @extend .level;
        color: #6F131D;
    } 

    .disabled{
        @extend .level;
        font-size: 14pt;
        color: #40090f47;
    }
}


.probabilityCard{
    @extend .Card;
    padding: 0 28px 8px 28px;
}

.probabilityCard2{
    @extend .probabilityCard;
    margin-top: 12px;
}

.buttomCard{
    @extend .Card;
    padding: 0 16px 25px 16px;
    width: 49%;
}
.buttomCard1{
    @extend .buttomCard;
    display: block;
}
.contentText{
    @extend .header;
    color: #6F131D;
    font-size: 20pt;
    margin-top: 0px;
    margin-bottom: 5.35px;
}
