
.contentContainer {
    display: flex;        // 使用 flex 布局
    flex-direction: row;  // 确保容器是水平排列的
  

  }
  
.headerContainer{
    font-size: 18pt;

    font-weight: 750; 
}

.header{
    text-align: center;
    margin: 0;
    margin-top:10.77px;
    font-size: 20px;
    margin-bottom: 10.77px;  

    font-weight: 750;  
}
.button{

    width: 77%;
    
    margin-top: 10.88px;
}

.hoverButton {
    padding-top: 12.8px;
    padding-bottom: 12.2px;
    @extend .button;
    position: relative;

    &:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        left: 0;
        bottom: 100%;  // 在按钮上方显示解释框
        background-color: #333;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        white-space: nowrap;  // 防止文本换行
        z-index: 1;  // 确保解释框在其他元素之上
        margin-bottom: 5px; // 与按钮之间的距离
    }
}
.moreButton {
    @extend .button;
    height: 44px;
    font-size: 30px;
    font-weight: 1000;
    color: #6F131D;
    background-color: #f0eeeeb4;

    border-radius: 8px;
    border: none;

    vertical-align: middle;
    vertical-align: -10px;
    padding: 0px 0px 0px 0px;

     
    &:hover {
      background-color: #8c5b5b;
      color: #ffffff;
    }
    &:active{
        background-color: #6F131D;
        color: #ffffff;
    }
    &:disabled{
        background-color: #F1F6F5;
        color: #136f5692;
    }
    
}
.inDropContainer{

    align-items: center; // 水平居中
    justify-content: center; // 垂直居中
    display: flex;
}
.inDropButton{
    @extend .hoverButton;
    margin-top: 0px;
    width: 100%;
}
.ContentCard{
    display: flex;
    align-items: center; // 水平居中;
    background-color: #ffffff;
    border-radius: 15px;
    flex-direction: column;
    padding: 5px 0 17px 0 ;
}
.leftContentCard{
    @extend .ContentCard;
    width: 100%;
    max-height: 80%;
    
}

.rightContentCard{
    @extend .ContentCard;
    flex-grow: 1;
    
}
.leftContentContainer{
    
    width: 250px;

}

.rightContentContainer{
    display: flex;
    flex-direction: column; /* Stack children vertically */
    width: 70%;

    margin-left: 19px;


}
.leftContentContainer2{
    height: 50%;
    max-height: 40vh;
}

.box{
    width: 79px;
    height: 32px;
    background-color: #134F6F;
}