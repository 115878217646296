
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.97vh 2vh;
    height: 7vh;
    background-color: #ffffff;
    border-bottom: 1px solid #e8e8e8;
    min-height: 50px;
  }
  
  .logo {
    margin-left: 2.7vw;
    padding-right: 2vw;
    font-weight: bold;
    font-size: 170%;
    color: #134F6F;
    cursor: pointer;
  }

//input
.searchInput {
    flex: 1;
    margin: 0 2rem;
    height: 82.3%;
  }



  

  .inputContainer {
    width: 100%;
    margin-left: 5vw;
    margin-right: 5vw;
    display: flex;
    align-items: center;
    background-color: #F4F4F4; 
    border-radius: 25px; 
    overflow: hidden;
    transition: background-color 0.3s; // 平滑地改变背景色

    &:focus-within {
        background-color: darken(#F4F4F4, 5%); // 使用SCSS函数使颜色变暗
    }
    }

    .inputStyle {
        border: none;
        background-color: transparent !important;
        flex: 1;

        &:hover, &:focus {
            background-color: transparent !important;
            box-shadow: none; 
            
        }
    }

    :global(.layoutInputContainer .ant-input){
        background-color: transparent !important;
        &:hover, &:focus {
            background-color: transparent !important;
        }
    }




    .iconStyle {
        color: #ccc; 
        margin-right: 10px;
        
    }

    .searchButton {
        background-color: #134F6F;
        border: none;
        color: #fff;
        border-radius: 50%;
        

        padding: 0;    // 移除任何内边距
        display: flex; // 使用flex布局来居中内容
        justify-content: center; // 水平居中
        align-items: center;     // 垂直居中
        transition: background-color 0.3s;

        &:hover {

            background-color: lighten(rgb(40, 121, 232), 10%);
        }
        &:focus {
            
            background-color: rgb(40, 121, 232);
            
        }
        .anticon {
            font-size: 24px;
        }
    }

//right button
.roundedButton {
    border-radius: 25px;  
    background-color: #134F6F;
    margin-right: 2.7vw;
    height: 94%;
    width: 16%;
    min-width: 130px;
    max-width: 230px;
    
    
}  

.layoutContent{
   min-width: 1200px;
}
